import React from "react";
import ReactDynamicImport from 'react-dynamic-import';
import { detectMob } from "@src/Helper";

const loader = () => {
  if (!detectMob()) {
    return (
      import('@components/ExmWallet/ExmWallet')
    )
  } else {
    return (
      import('@components/ExmWallet/ExmWalletMobile')
    )
  }
}

const ExmWallet = ReactDynamicImport({ loader })

const ExmWalletPage = () => <ExmWallet />

export default ExmWalletPage;
